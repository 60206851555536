@import url("https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@400;500;600&display=swap");
// Variables
@import "variables";

$utilities: (
    "bg-opacity": (
        css-var: true,
        class: bg-opacity,
        values: (
            10: 0.1,
            25: 0.25,
            50: 0.5,
            75: 0.75,
            90: 0.9,
            100: 1,
        ),
    ),
);
// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "../css/app.css";
