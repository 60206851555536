body {
    /*by abuSpiha*/
    font-family: "Noto Naskh Arabic", serif !important;
    background-color: #eaeaea !important;
}

.cursor-pointer {
    cursor: pointer;
}

.dropdown {
    position: relative;
    /* display: inline-block; */
}

.dropdown-content {
    /* display: none; */
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
}

.w-10 {
    width: 10%;
}

.w-90 {
    width: 90%;
}

/* .on-hover-text-green:hover * {
    color: #00a65a;
} */

/* .scaleUp:hover {
    -webkit-transform: scale(1.1);
} */

.index-bg {
    /* body background */
}

/*abuSpiha edit*/

.sideMenu {
    min-height: 100vh;
    background-color: #3c4048;
    font-size: 1.2rem;
    padding: 1rem 0;
}

.brand-border {
    border-bottom: 3px solid #92b4ec !important;
}

.menu-links {
    padding: 0.4rem 0.5rem;
    text-decoration: none;
    font-weight: bold;
    color: #eaeaea !important;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}

.menu-links:hover {
    color: #000000 !important;
    background-color: #92b4ec;
    letter-spacing: 0.75px;
}

.active-page {
    color: #000000 !important;
    background-color: #b2b2b2;
}

.dropMenu-bg {
    background-color: #ffffff1a;
}

.dropMenu-links {
    font-size: 1.1rem !important;
    font-weight: bold;
    text-decoration: none;
    color: #92b4ec;
    transition: all ease-in-out 0.3s;
}

.dropMenu-links:hover {
    color: #ffe69a;
    letter-spacing: 1px;
}

.footer-text {
    font-size: 1rem;
}

.btn {
    transition: all ease-in-out 0.2s !important;
}

.btn:hover {
    /* letter-spacing: 1px; */
    transform: scale(1.08) !important;
}
